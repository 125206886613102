@import 'src/scss/base';

.form-row {
  display: flex;
  flex-direction: column;
  margin-bottom: $single-gap;
  font-size: $font-size;

  .label {
    display: flex;
    justify-content: space-between;
    margin-bottom: $single-gap;

    .optional {
      color: $color-light-slate-gray;
    }
  }
}
