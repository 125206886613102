@import 'src/scss/base';

.info-panel {
  position: relative;
  display: flex;
  width: 100%;
  margin-bottom: $single-gap;
  font-size: $font-size;
  box-shadow: 0 2px 4px 0 rgba($color-black, 0.2);

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    background-color: $color-grey-smoke;
  }

  .panel {
    width: 100%;

    &.info {
      display: flex;
      justify-content: space-between;
      padding: $double-gap;
      background-color: $color-white;

      &.column {
        flex-direction: column;
      }
    }
  }

  .waiting-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: $quadruple-gap;
    background-color: $color-white;
  }

  .spinner {
    width: $font-size-xxl;
    height: $font-size-xxl;
    margin-bottom: $single-gap;
  }
}
