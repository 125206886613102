@import 'src/scss/base';

.tab {
  @include button-reset();
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: $color-dark-denim;
  font-weight: $font-weight-medium;
  font-family: $font-primary;
  text-align: center;
  background-color: $color-geyser;

  &.disabled {
    color: $color-light-slate-gray;
    cursor: default;
  }

  &.selected {
    background-color: transparent;
    cursor: default;
  }
}
