@import 'src/scss/base';

.radio-switcher-as-blocks {
  display: flex;

  &.in-column {
    flex-direction: column;

    label:not(:last-child) .text {
      border-bottom: 0;
    }
  }

  &.in-row {
    flex-direction: row;

    label:not(:first-child) .text {
      border-left: 0;
    }
  }

  .radio-input {
    position: absolute;
    width: 0;
    height: 0;
    outline: none;
    visibility: hidden;
    appearance: none;

    &:disabled:checked + span {
      color: $color-white;
      background: $color-dark-denim;
      border-color: $color-dark-denim;
    }

    &:disabled + span {
      background: $color-white;
      opacity: 0.6;
    }

    &:checked + span {
      color: $color-white;
      background: $color-dark-denim;
      border-color: $color-dark-denim;
    }
  }

  .text {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-dark-denim;
    text-align: left;
    text-transform: uppercase;
    background: none;
    border: 1px solid $color-dark-denim;
    user-select: none;

    @include bp(padding, $single-gap, $single-gap + $half-gap $triple-gap, sm);
    @include bp(font-size, $font-size, $font-size-m, sm);
    @include bp(font-weight, $font-weight-regular, $font-weight-medium, sm);

    svg {
      margin-right: $single-gap;
    }
  }
}
