@import 'src/scss/base';

.loader {
  display: flex;
  align-items: center;
  align-self: center;
  height: 100vh;

  .spinner {
    width: $font-size-xxl;
    height: $font-size-xxl;

    path {
      fill: $color-white;
    }
  }
}
