@import 'src/scss/base';

.modal {
  @include bp(width, 100%, $breakpoint-up-xxs, sm);

  margin-right: $double-gap;
  margin-left: $double-gap;
  background: $color-white;
  box-shadow: 0 $single-gap $double-gap 0 rgba($color-black, 0.25), 0 $half-gap $half-gap 0 rgba($color-black, 0.25);

  &.scroll {
    height: 100%;
    margin: 0;
    overflow-y: scroll;

    @include bp(width, 100%, calc(95% - 40px), sm);

    @media screen and (orientation: landscape) {
      @include bp(width, 100%, calc(60% - 40px), sm);
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: $triple-gap $double-gap;

    &.without-gap {
      padding: 0;
    }
  }

  .body {
    margin-bottom: $double-gap;
    font-size: $font-size;
    text-align: center;
  }

  .footer {
    display: flex;
    flex-direction: column;
  }
}
