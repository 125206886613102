/* stylelint-disable */
@import 'src/scss/base';

$gradient-sm-up-step-1: rgba($color-black-pearl, 0) 70px;
$gradient-sm-up-step-2: rgba($color-black-pearl, 0.4) 100px;
$gradient-sm-up-step-3: rgba($color-black-pearl, 1) 170px;

$gradient-sm-down-step-1: rgba($color-black-pearl, 0) 100px;
$gradient-sm-down-step-2: rgba($color-black-pearl, 0.4) 130px;
$gradient-sm-down-step-3: rgba($color-black-pearl, 1) 350px;

$gradient-sm-down: linear-gradient(
  to bottom,
  $gradient-sm-down-step-1,
  $gradient-sm-down-step-2,
  $gradient-sm-down-step-3
);
$gradient-sm-up: linear-gradient(to bottom, $gradient-sm-up-step-1, $gradient-sm-up-step-2, $gradient-sm-up-step-3);

.background {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background-color: $color-black-pearl;
  background-repeat: no-repeat;
  background-size: 100%;

  @include bp(
    background-image,
    #{$gradient-sm-up,
    var(--bg-image, none)},
    #{$gradient-sm-down,
    var(--bg-image, none)},
    sm
  );

  @include bp(background-position, 0 $header-height-mobile, 0 $header-height-tablet, sm);

  &.stick-bg-to-top {
    background-position: top;
  }
}

.page {
  min-height: 100vh;
  width: 100%;

  .page-content {
    display: flex;
    flex-direction: column;
    background: none;

    @include bp(margin-left, 0, 23px, sm);
    @include bp(margin-right, 0, 17px, sm);

    &.flex-content {
      display: flex;
    }
  }

  &.show-as-popup {
    width: 95%;
    margin: $quintuple-gap $double-gap + $half-gap 0;

    .page-content {
      margin: 0;
    }

    @media screen and (orientation: landscape) {
      width: 60%;
    }
  }
}
