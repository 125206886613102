@import '../../scss/base';

.out-of-stock-message {
  display: flex;
  margin-top: $double-gap;
  font-weight: $font-weight-regular;

  @include bp(font-size, $font-size, $font-size-l, sm);

  > p {
    margin-left: $single-gap;
  }
}
