@import 'src/scss/base';

.bottom-navigation {
  @include bp(
    height,
    calc(env(safe-area-inset-bottom) + #{$bottom-navigation-height-mobile}),
    calc(env(safe-area-inset-bottom) + #{$bottom-navigation-height-tablet}),
    sm
  );

  position: relative;
  z-index: $z-index-footer;
  display: flex;
  width: 100%;
  padding-bottom: env(safe-area-inset-bottom);
  background: $color-dark-denim;

  @media screen and (orientation: landscape) {
    height: calc(env(safe-area-inset-bottom) + #{$bottom-navigation-height-mobile});
  }
}

.tab {
  @include button-reset();
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: $font-weight-regular;
  font-size: $font-size-xxs;
  font-family: $font-primary;
  text-transform: uppercase;
  background: $color-dark-denim;

  &:first-child {
    border-right: $border-side-big-stone;
  }

  &:last-child {
    border-left: $border-side-big-stone;
  }

  .label {
    color: $color-white;
  }

  .icon {
    margin-bottom: 10px;
  }

  &.active {
    background: $color-blue-midnight;

    .label {
      color: $color-bright-turquoise;
    }

    .icon {
      path {
        fill: $color-bright-turquoise;
      }
    }
  }
}
