@import 'src/scss/base';

.service-tabs {
  display: flex;
  width: auto;

  .tab {
    margin-right: $single-gap;
    padding: 10px 21px;
    color: $color-white;
    font-size: $font-size-m;
    text-transform: uppercase;
    background-color: $color-dark-denim;

    &:last-child {
      margin-right: 0;
    }
  }

  .active {
    color: $color-dark-denim;
    background-color: $color-white;
  }
}
