@import '../../scss/base';

.products {
  .product {
    @include bp(flex-direction, column, row, sm);
    @include bp(margin-bottom, $single-gap, 11px, sm);

    display: flex;
    background: $color-white;

    &.unavailable {
      .product-image,
      .dish {
        opacity: 0.5;
      }
    }

    &.with-border-bottom {
      border-bottom: $border-side-geyser;
    }

    &.bottom {
      @include bp(padding-bottom, $double-gap, $single-gap, sm);

      .product-image {
        @include bp(margin-right, 0, $double-gap, sm);
      }

      .dish-wrapper {
        @include bp(padding-bottom, 0, $single-gap, sm);
      }
    }

    &.top-right-bottom-left {
      .product-image {
        margin-bottom: 0;
      }

      .dish-wrapper {
        @include bp(padding, $double-gap, 23px, sm);
      }
    }

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: unset;
    }
  }

  .product-image {
    @include bp(width, 100%, 220px, sm);
    @include bp(margin-bottom, $double-gap, 0, sm);
    @include bp(height, 150px, inherit, sm);

    object-fit: cover;

    &.align-center {
      align-self: center;
    }

    @media screen and (orientation: landscape) {
      @include bp(width, 100%, 250px, sm);
    }
  }

  .dish-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .dish {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .dish-description {
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  .title {
    @include bp(font-size, $font-size-m, 22px, sm);
    @include bp(line-height, 20px, 28px, sm);

    font-size: $font-size-m;
  }

  .description {
    @include bp(font-size, $font-size-xs, $font-size-m, sm);
    @include bp(line-height, 16px, 20px, sm);

    @media screen and (orientation: landscape) {
      @include bp(font-size, $font-size-xs, 15px, sm);
      @include bp(margin-top, $font-size-xs, 11px, sm);
    }

    margin-top: $single-gap;
    font-weight: $font-weight-regular;
  }

  .price-and-counter {
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-left: $double-gap;

    @media screen and (orientation: landscape) {
      @include bp(flex-direction, column, row, sm);
      @include bp(align-items, auto, center, sm);
    }
  }

  .price {
    @include bp(font-size, $font-size, $font-size-l, sm);
    @include bp(margin-bottom, $single-gap, 12px, sm);

    @media screen and (orientation: landscape) {
      @include bp(margin-bottom, $single-gap, 0, sm);
      @include bp(margin-right, 0, 40px, sm);
      @include bp(width, auto, max-content, sm);
    }

    text-align: center;
  }
}
