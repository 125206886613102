@import 'src/scss/base';

.service-availability {
  @include bp(margin, $single-gap 0 $double-gap, 11px 0 0, sm);
  @include bp(padding, $half-gap $double-gap, 7px 18px, sm);
  @include bp(font-size, $font-size, 18px, sm);

  @media screen and (orientation: landscape) {
    @include bp(padding, $half-gap $double-gap, 10px 18px, sm);
  }

  width: fit-content;

  &.closed {
    background-color: $color-grey-smoke;
    box-shadow: $box-shadow-inset $color-new-york-pink;
  }

  &.open {
    background-color: $color-light-turquoise;
    box-shadow: $box-shadow-inset $color-caribbean-green;
  }
}
