@import 'src/scss/base';

.confirmation-panel {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  text-align: center;

  .icon {
    width: 40px;
    height: 40px;

    polygon {
      fill: $color-dark-denim;
    }
  }

  .title {
    margin: $double-gap 0 $single-gap;
    font-weight: $font-weight-medium;
    font-size: $font-size-xxl;
    line-height: 26px;
    text-transform: uppercase;
  }

  .value {
    margin-top: $half-gap;
    font-weight: $font-weight-medium;
    font-size: $font-size-m;
    line-height: 20px;
  }

  .information {
    padding: $single-gap $double-gap;
  }
}
