@import 'src/scss/base';

.field {
  height: 40px;
  padding: 10px 16px;
  color: $color-dark-denim;
  font-size: $font-size-m;
  font-family: $font-primary;
  border: solid 1px $color-light-slate-gray;
  border-radius: 0;
  box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.2);
  -webkit-appearance: none;

  &:focus {
    border: solid 1px $color-dark-denim;
    outline: none;
  }

  &:disabled {
    color: $color-dark-denim;
    background-color: $color-porcelain;
    border: solid 1px $color-geyser;
    box-shadow: none;
    -webkit-text-fill-color: $color-dark-denim;
  }

  &::placeholder {
    color: $color-silver;
  }
}

.invalid {
  background-color: $color-your-pink;
  border: solid 1px $color-new-york-pink;

  &:focus {
    background-color: $color-white;
    border: solid 1px $color-new-york-pink;
    outline: none;
  }

  &.clearable {
    padding-right: 40px;
  }
}

.small {
  height: 28px;
  padding-right: 8px;
  padding-left: 8px;
  font-size: $font-size;

  &.clearable.invalid {
    padding-right: 22px;
  }
}
