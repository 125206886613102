@import 'src/scss/base';

.with-px {
  $control-width: 65px;
  $button-size: 20px;
  $control-width-large: 100px;
  $button-size-medium: 24px;
  $button-size-large: 32px;
  $control-width-large-tablet: 130px;
  $button-size-large-tablet: 45px;
  $default-line-height: $button-size - 2px;
  $large-line-height: $button-size-large - 2px;
  $tablet-line-height: $button-size-large-tablet - 2px;
  $button-height: $button-height-mobile;

  @import 'PlusMinus.styles';
}

.with-rem {
  $control-width: 6.5rem;
  $button-size: 2rem;
  $line-height: 1.8rem;
  $control-width-large: 10rem;
  $button-size-medium: 2.4rem;
  $button-size-large: 3.2rem;
  $control-width-large-tablet: 13rem;
  $button-size-large-tablet: 4.5rem;
  $default-line-height: $button-size - 0.2rem;
  $large-line-height: $button-size-large - 0.2rem;
  $tablet-line-height: $button-size-large-tablet - 0.2rem;
  $button-height: 4rem;

  @import 'PlusMinus.styles';
}
