@import '../../scss/base';

.payment-failed {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  text-align: center;

  .icon {
    width: 40px;
    height: 40px;
  }

  .title {
    padding: $double-gap;
    font-weight: $font-weight-medium;
    font-size: $font-size-xxl;
    line-height: 26px;
    text-transform: uppercase;
  }

  .description {
    padding: 0 $double-gap;
    line-height: 18px;
  }
}
