@import 'src/scss/base';

.icon {
  flex-shrink: 0;
}

.xxs {
  width: 8px;
  height: 8px;
}

.xs {
  width: 10px;
  height: 10px;
}

.small {
  width: 14px;
  height: 14px;
}

.medium {
  width: 16px;
  height: 16px;
}

.original {
  width: unset;
  height: unset;
}

.large {
  width: 18px;
  height: 18px;
}

.xl {
  width: 24px;
  height: 24px;
}

.xxl {
  width: 26px;
  height: 26px;
}

.xxxl {
  width: 28px;
  height: 28px;
}

.dark-denim {
  path {
    fill: $color-dark-denim;
  }
}

.buoy {
  path {
    fill: $color-buoy;
  }
}

.white {
  path {
    fill: $color-white;
  }
}

.light-slate-gray {
  path {
    fill: $color-light-slate-gray;
  }
}

.new-york-pink {
  path {
    fill: $color-new-york-pink;
  }
}

.bright-turquoise {
  path {
    fill: $color-bright-turquoise;
  }
}

.light-turquoise {
  path {
    fill: $color-light-turquoise;
  }
}

.viking {
  path {
    fill: $color-viking;
  }
}

.geyser {
  path {
    fill: $color-geyser;
  }
}

.times-icon {
  display: inline-block;
  margin: 0 $half-gap;
  font-size: 1.4rem;
  line-height: 1rem;
}
