@import 'src/scss/base';

$color-dark-aquamarine: #00edca;

.app-promotion {
  margin: $single-gap;
  padding: 0;
  color: $color-dark-denim;
  background-color: $color-white;
  border: 0;

  .image-content {
    position: relative;

    .image {
      display: block;
      width: 100%;
      height: 197px;
      object-fit: cover;
    }

    .dismiss {
      position: absolute;
      top: $double-gap;
      right: $single-gap;
      color: $color-dark-aquamarine;
      text-transform: uppercase;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: $double-gap;

    .text {
      padding-bottom: $single-gap;
    }
  }
}
