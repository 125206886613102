@import 'src/scss/base';

.spinner-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: $color-white;
  font-weight: $font-weight-regular;
  font-size: $font-size;

  @include bp(
    height,
    calc(100% - #{$bottom-navigation-height-mobile}),
    calc(100% - #{$bottom-navigation-height-tablet}),
    sm
  );

  .spinner {
    width: $font-size-xxl;
    height: $font-size-xxl;
    margin-bottom: $double-gap;

    path {
      fill: $color-white;
    }
  }
}

.alphachat-container {
  position: relative;
  margin: $double-gap $single-gap;
}
