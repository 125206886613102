.small {
  height: 14px;
}

.medium {
  height: 16px;
}

.large {
  height: 28px;
}
