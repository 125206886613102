@import 'src/scss/base';

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $z-index-overlay;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba($color-black, 0.25);
}
