@import 'src/scss/base';

.service-page {
  padding-bottom: calc(env(safe-area-inset-bottom) + #{$gap-for-pay-button});

  @include bp(margin, 76px $double-gap 0, 150px $double-gap 0, sm);

  .title {
    color: $color-white;
    font-size: 28px;
    text-transform: uppercase;
  }

  .show-info {
    display: flex;
    align-items: center;
    margin: $double-gap 0;
    color: $color-bright-turquoise;
    text-transform: uppercase;

    .chevron-icon {
      margin-left: 7px;
    }
  }
}
