@import 'src/scss/base';

.accordion {
  display: flex;
  flex-direction: column;
  color: $color-dark-denim;
  font-size: $font-size-m;
  border-bottom: $color-grey-smoke 1px solid;

  &:last-of-type {
    border-bottom: unset;
  }

  .header {
    @include button-reset();
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $double-gap;
    font-family: $font-primary;

    @include focus-outline;

    &:focus-visible {
      outline-offset: -1px !important;
    }

    .text {
      font-weight: $font-weight-medium;
      font-size: inherit;
      text-transform: uppercase;

      &.left {
        text-align: left;
      }

      &.center {
        text-align: center;
      }

      @include media-breakpoint-up(sm) {
        font-size: $font-size-xxl;
      }
    }
  }

  .body {
    padding: 0 $double-gap $double-gap;
  }

  .padding-none {
    position: relative;
    padding: 0;
  }
}
