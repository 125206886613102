@import 'src/scss/base';

.book-table {
  margin-right: -$double-gap;
  margin-left: -$double-gap;
  padding: 25px $double-gap;
  background: $color-white;

  .title {
    padding-bottom: $half-gap;
    font-weight: $font-weight-regular;
    font-size: $font-size;
  }

  .value {
    color: inherit;
    font-weight: $font-weight-medium;
    font-size: $font-size-m;
    text-decoration: none;
  }

  .title,
  .value {
    text-align: center;
  }

  .opening-times,
  .reception-phone {
    display: flex;
    flex-direction: column;
    margin-top: $double-gap;
  }

  .reception-phone {
    padding-bottom: $double-gap;
    border-bottom: $border-side-geyser;
  }

  .post-scriptum {
    padding-top: $double-gap;
    color: $color-light-slate-gray;
    font-weight: $font-weight-regular;
    font-size: $font-size-xs;
    text-align: center;
  }
}
