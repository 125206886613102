@import 'src/scss/base';

.categories {
  display: flex;
  flex-direction: column;
  margin-right: -$double-gap;
  margin-left: -$double-gap;
  background-color: $color-white;

  .accordion {
    font-size: $font-size-xxl;
  }

  .icon {
    margin-left: $single-gap;
  }

  .category-title {
    display: flex;
    align-items: center;
    padding-right: $double-gap;
  }
}
