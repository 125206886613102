@import 'src/scss/base';

.step {
  display: flex;
  flex-direction: column;
  margin-bottom: $single-gap;

  &.unavailable {
    color: $color-light-slate-gray;

    .panel {
      &.border-left-todo-todo,
      &.border-left-done-done,
      &.border-left-todo-done,
      &.border-left-done-todo {
        border-color: $color-geyser;
        border-image: unset;
      }
    }

    svg {
      path {
        &:first-child {
          fill: $color-geyser;
        }

        &:last-child {
          fill: $color-light-slate-gray;
        }
      }
    }
  }

  .heading-container {
    display: flex;
    align-items: center;
  }

  .heading {
    font-size: $font-size-xl;
    text-transform: uppercase;
  }

  .spinner {
    width: 20px;
    height: 20px;
    margin-left: $double-gap;
  }

  .panel {
    margin: $single-gap 0 0 12px;
    padding: 0 0 20px 20px;
    border-left: 2px solid transparent;

    &.border-left-todo-todo {
      border-color: $color-dark-denim;
    }

    &.border-left-done-done {
      border-color: $color-buoy;
    }

    &.border-left-todo-done {
      border-image: linear-gradient(to top, $color-caribbean-green, $color-dark-denim);
      border-image-slice: 1;
      border-image-width: 0 0 0 2px;
    }

    &.border-left-done-todo {
      border-image: linear-gradient(to bottom, $color-caribbean-green, $color-dark-denim);
      border-image-slice: 1;
      border-image-width: 0 0 0 2px;
    }
  }

  .comment {
    padding-bottom: $double-gap;
    font-weight: $font-weight-regular;
    font-size: $font-size;
  }
}
