@import 'src/scss/base';

.button-with-spinner {
  display: flex;
  justify-content: center;
  min-height: rem(40);
  margin: auto;

  .spinner {
    width: 20px;
    height: 20px;
  }
}
