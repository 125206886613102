@import 'src/scss/base';

.counter-item {
  display: flex;
  align-items: baseline;
  font-size: 1.4rem;
  line-height: 1.8rem;

  &.inactive {
    opacity: 0.2;
  }

  .count {
    @extend %font-light;
  }
}
