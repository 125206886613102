//
// Colors Definition
// color names are generated with http://chir.ag/projects/name-that-color/
//
$color-alabaster: #fbfbfb;
$color-alto: #dadada;
$color-aquamarine: #6cffdc;
$color-black-pearl: #00112d;
$color-black: #000;
$color-blue-midnight: #17273f;
$color-blue-whale: #033a47;
$color-bright-turquoise: #00ecc9;
$color-buoy: #009d85;
$color-caribbean-green: #00bea2;
$color-cinnabar: #e5312a;
$color-contessa: #c06f6f;
$color-dark-blue: #004c93;
$color-dark-denim: #004152;
$color-dark-faded-blue: #ccd8da;
$color-denim: #003f53;
$color-gallery: #eee;
$color-geyser: #d6dfdf;
$color-grey-cloud: #e5ebed;
$color-grey-coin: #ddd;
$color-grey-light: #eaefef;
$color-grey-lighter: #f5f7f7;
$color-grey-smoke: #eef1f1;
$color-gumbo: #80a0a8;
$color-humming-bird: #cdf8eb;
$color-light-grey: #fbfbfb;
$color-light-peach: #ffc8be;
$color-light-slate-gray: #7799a1;
$color-light-turquoise: #cdf8eb;
$color-mauvelous: #f49090;
$color-new-york-pink: #d97e7e;
$color-onahau: #c2f7ff;
$color-porcelain: #f5f8f7;
$color-robin-egg-blue: #00d4b4;
$color-silver: #959595;
$color-slate-grey: #466b77;
$color-smoke: #fafbfb;
$color-teal: #396a76;
$color-turquoise: #7ed;
$color-viking: #6ecbd9;
$color-white: #fff;
$color-your-pink: #ffc8be;
$color-botticelli: #c2d7de;
$color-astra: #fafab4;
$color-bright-sun: #ffd646;
$color-faded-jade: #40717d;

$button-height-desktop: 28px;
$button-height-mobile: 40px;

//
// Breakpoints
//
$breakpoint-up-xxs: 320px;
$breakpoint-up-xs: 576px;
$breakpoint-up-sm: 768px;
$breakpoint-up-md: 992px;
$breakpoint-up-lg: 1200px;
$breakpoint-up-xl: 1360px;

//
// Layout
//
$content-max-width: 960px;

//
// Gaps
//
$half-gap: 4px;
$single-gap: 8px;
$double-gap: 16px;
$triple-gap: 24px;
$quadruple-gap: 32px;
$quintuple-gap: 40px;

//
// Fonts
//
$gt-walsheim: 'GTWalsheim', Tahoma, Arial, Helvetica, sans-serif;
$tallink-script-bold: 'TallinkScriptBold', serif;

$font-primary: $gt-walsheim;
$font-tallink-brand: $tallink-script-bold;

$font-size-xxs: 10px;
$font-size-xs: 12px;
$font-size-s: 13px;
$font-size: 14px;
$font-size-m: 16px;
$font-size-l: 18px;
$font-size-xl: 20px;
$font-size-xxl: 24px;

$font-weight-regular: 200;
$font-weight-medium: 500;
$font-weight-bold: 700;

//
// z-indexes
//
$z-index-overlay: 10000;
$z-index-spinner: 10;
$reservation-changes-fixed-z-index: 1000;

$transition-time: 70ms;
