@import 'src/scss/base';

.text {
  color: $color-dark-denim;
  font-size: $font-size;
  line-height: 18px;

  a {
    color: $color-dark-denim;
    font-weight: $font-weight-medium;
    @include link;
  }

  &.regular {
    font-weight: $font-weight-regular;
  }

  &.medium {
    font-weight: $font-weight-medium;
  }

  &.bold {
    font-weight: $font-weight-bold;
  }

  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }
}
