@import 'src/scss/base';

.callout {
  display: flex;
  flex-flow: row;
  padding: 16px;
  font-size: $font-size;
  font-family: $font-primary;
  background-color: $color-humming-bird;
  border-top: 1px solid $color-dark-denim;

  &.error {
    background-color: $color-your-pink;
  }
}

.content {
  display: flex;
  flex-flow: column;
  padding-left: $single-gap;

  @include media-breakpoint-up(sm) {
    flex-flow: row;
    padding-left: $double-gap;
  }
}

.header {
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  font-weight: $font-weight-medium;
  font-size: $font-size-m;
  text-transform: uppercase;

  @include media-breakpoint-up(sm) {
    font-size: $font-size-xl;
  }
}

.body {
  font-weight: $font-weight-regular;
}

.divider {
  @include media-breakpoint-up(sm) {
    padding-right: 24px;
    padding-left: 16px;
  }

  hr {
    height: 1px;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: $color-dark-denim;
    border: 0;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

  .vertical {
    display: none;

    @include media-breakpoint-up(sm) {
      display: block;
      height: 100%;
      border-left: 1px solid $color-dark-denim;
    }
  }
}

.summary {
  font-weight: $font-weight-regular;

  @include media-breakpoint-up(sm) {
    display: block;
    align-self: center;
    min-width: 270px;
    padding-left: 8px;
  }
}
