&.plus-minus {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $control-width;
  height: $button-size;
  margin: 0 auto;
  color: $color-white;
  font-size: $font-size-m;

  .value {
    box-sizing: border-box;
    width: $control-width - $button-size;
    height: $button-size;
    font-size: $font-size;
    line-height: $default-line-height;
    text-align: center;
    background: $color-dark-denim;
    border: 1px solid $color-dark-denim;
    transition: all ease-in-out $transition-time;

    &.inactive {
      color: $color-geyser;
      background: $color-white;
      border-color: $color-geyser;
    }
  }

  .button-icon {
    position: absolute;

    &.minus {
      left: 0;
    }

    &.plus {
      right: 0;

      &.max-value {
        svg circle {
          fill: $color-gumbo;
        }
      }
    }

    &:focus-visible,
    &:hover {
      svg circle {
        fill: $color-geyser;
      }
    }

    @media not all and (pointer: fine) {
      &:hover {
        svg circle {
          fill: $color-white;
        }
      }
    }

    svg {
      circle,
      path {
        transition: all ease-in-out $transition-time;
      }
    }
  }

  &.initial {
    .value {
      color: $color-light-slate-gray;
      background: $color-grey-light;
      border: 1px solid $color-grey-light;
    }

    .button-icon {
      &.minus {
        svg circle {
          fill: $color-grey-lighter;
          stroke: $color-grey-light;
        }

        svg path {
          fill: $color-light-slate-gray;
        }
      }

      &.plus {
        svg circle {
          stroke: $color-grey-light;
        }

        svg path {
          fill: $color-dark-denim;
        }
      }
    }
  }

  &.disabled {
    .value {
      color: $color-light-slate-gray;
      background: $color-grey-lighter;
      border: 1px solid $color-grey-lighter;
    }

    .button-icon {
      &.plus,
      &.minus {
        svg circle {
          fill: $color-smoke;
          stroke: $color-grey-lighter;
        }

        svg path {
          opacity: 0.25;
          fill: $color-light-slate-gray;
        }
      }
    }
  }

  &.original {
    .button-icon {
      width: $button-size;
      height: $button-size;
    }
  }

  &.medium {
    .button-icon {
      width: $button-size-medium;
      height: $button-size-medium;

      svg {
        width: $button-size-medium;
        height: $button-size-medium;
      }
    }
  }

  &.large {
    width: $control-width-large;
    height: $button-size-large;

    .value {
      width: $control-width-large - $button-size-large;
      height: $button-size-large;
      font-size: $font-size;
      line-height: $large-line-height;
    }

    .button-icon {
      width: $button-size-large;
      height: $button-size-large;

      svg {
        width: $button-size-large;
        height: $button-size-large;
      }
    }
  }

  &.tablet {
    width: $control-width-large-tablet;
    height: $button-size-large-tablet;

    .value {
      width: $control-width-large-tablet - $button-size-large-tablet;
      height: $button-size-large-tablet;
      font-size: $font-size-m;
      line-height: $tablet-line-height;
    }

    .button-icon {
      width: $button-size-large-tablet;
      height: $button-size-large-tablet;

      svg {
        width: $button-size-large-tablet;
        height: $button-size-large-tablet;
      }
    }
  }
}

&.classic {
  &.initial {
    .value {
      color: $color-geyser;
      background: $color-white;
      border: 1px solid $color-geyser;
    }

    .button-icon {
      &.minus {
        svg circle {
          fill: $color-geyser;
          stroke: $color-geyser;
        }

        svg path {
          fill: $color-white;
        }
      }

      &.plus {
        svg circle {
          stroke: $color-dark-denim;
        }
      }
    }
  }
}

&.button {
  width: 100%;
  height: $button-height;
  background-color: $color-dark-denim;
  border: 1px solid $color-dark-denim;

  .button-icon {
    &.minus {
      left: $single-gap;
    }

    &.plus {
      right: $single-gap;
    }
  }

  &.initial {
    color: $color-dark-denim;
    background-color: $color-white;

    .value {
      color: $color-dark-denim;
      background-color: $color-white;
      border: 0;
    }

    .button-icon {
      &.minus {
        svg circle {
          fill: $color-grey-light;
          stroke: $color-grey-light;
        }

        svg path {
          fill: $color-white;
        }
      }

      &.plus {
        svg circle {
          fill: $color-dark-denim;
        }

        svg path {
          fill: $color-white;
        }
      }

      &.max-value {
        svg circle {
          fill: $color-gumbo;
        }
      }
    }
  }
}
