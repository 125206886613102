@import 'src/scss/base';

.alcohol-restriction-warning {
  display: flex;
  margin-bottom: $triple-gap;
  font-size: $font-size;

  .icon {
    margin-right: $single-gap;
  }
}
