@import 'src/scss/base';

$primary-background-hover: $color-aquamarine;
$primary-background-active: $color-caribbean-green;
$primary-background: $color-bright-turquoise;
$primary-font-color: $color-dark-denim;
$primary-border-color: unset;

$secondary-background-hover: $color-grey-smoke;
$secondary-background-active: $color-geyser;
$secondary-background: $color-white;
$secondary-font-color: $color-dark-denim;
$secondary-border-color: $color-dark-denim;

$danger-background-hover: $color-mauvelous;
$danger-background-active: $color-contessa;
$danger-background: $color-new-york-pink;
$danger-font-color: $color-white;
$danger-border-color: unset;

$text-background-hover: $color-grey-smoke;
$text-background-active: $color-geyser;
$text-background: unset;
$text-font-color: $color-dark-denim;
$text-border-color: unset;

@mixin button($background, $background-hover, $background-active, $font-color, $border-color) {
  color: $font-color;
  background: $background;

  @if ($border-color != unset) {
    border: 1px solid $border-color;
  }

  &[disabled] {
    color: mix($font-color, $color-white, 50%);
    @if $background != unset {
      background-color: mix($background, $color-white, 50%);
    }
    @if ($border-color != unset) {
      border: 1px solid mix($border-color, $color-white, 50%);
    }
    cursor: default;

    &:hover {
      @if $background != unset {
        background-color: mix($background, $color-white, 50%);
      }
    }
  }

  &:hover {
    background-color: $background-hover;
  }

  &:active {
    background-color: $background-active;
  }

  @include focus-outline;

  &:focus-visible {
    background-color: $background-hover;

    &:active {
      background-color: $background-active;
    }
  }

  &.full-width {
    width: 100%;
  }
}

.button {
  @include button-reset();

  display: flex;
  align-items: center;
  justify-content: center;
  min-height: $button-height-mobile;
  padding: 10px $triple-gap;
  font-weight: $font-weight-medium;
  font-size: $font-size-m;
  font-family: $font-primary;
  text-transform: uppercase;
  text-decoration: none;

  @include focus-outline;

  &.primary {
    @include button(
      $primary-background,
      $primary-background-hover,
      $primary-background-active,
      $primary-font-color,
      $primary-border-color
    );
  }

  &.secondary {
    @include button(
      $secondary-background,
      $secondary-background-hover,
      $secondary-background-active,
      $secondary-font-color,
      $secondary-border-color
    );
  }

  &.danger {
    @include button(
      $danger-background,
      $danger-background-hover,
      $danger-background-active,
      $danger-font-color,
      $danger-border-color
    );
  }

  &.text {
    @include button(
      $text-background,
      $text-background-hover,
      $text-background-active,
      $text-font-color,
      $text-border-color
    );
  }
}
