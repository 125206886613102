@import 'src/scss/base';

.anonymous-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .logo {
    margin-top: $single-gap;
  }

  .welcome-to {
    margin: $double-gap 0 $single-gap;
  }
}
