@import 'src/scss/base';

.logged-in-panel {
  .cards-panel {
    padding: 0 0 0 $double-gap;
    background-color: $color-white;
  }

  .row {
    margin: 0;
    padding: $single-gap 0;

    &:last-child {
      padding-bottom: $single-gap;
    }
  }

  .personal-club-one-card {
    display: flex;
    align-items: center;
    width: 60%;
  }

  .club-one-card-icon {
    height: 20px;
    margin-left: $single-gap;
  }

  .rule {
    height: 1px;
    margin-top: 12px;
    margin-bottom: 12px;
    background-color: $color-grey-smoke;
    border: 0;
  }

  .medium-text {
    font-weight: $font-weight-medium;
    font-size: $font-size-m;
  }

  .payment-wrapper {
    display: flex;
    align-content: center;
    justify-content: space-between;
    width: 100%;
  }

  .payment {
    @include bp(padding-top, $half-gap, 0, sm);

    display: flex;
    flex-direction: column;
  }
}
