@import 'src/scss/base';

.service-details {
  font-weight: $font-weight-regular;
  font-size: $font-size;

  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .column {
    margin-top: 20px;
    margin-right: 14px;
    padding-right: 14px;
    border-right: $border-side-dark-denim;

    &:last-child {
      margin-right: 0;
      padding-right: 0;
      border-right: unset;
    }
  }

  .title {
    color: $color-light-slate-gray;
  }

  .value {
    color: $color-white;
  }

  a {
    text-decoration: none;
  }

  .service-description {
    margin-top: 20px;
    padding-top: $double-gap;
    color: $color-white;
    border-top: $border-side-dark-denim;
  }
}
