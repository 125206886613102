@import 'src/scss/base';

.spinner {
  width: 16px;
  height: 16px;
  animation: rotate 2.5s linear infinite;

  path {
    fill: $color-dark-denim;
  }

  @keyframes rotate {
    50% {
      transform: rotate(230deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
