@import 'src/scss/base';

.sale-point-teaser {
  @include bp(margin, $single-gap, 10px 0, sm);

  display: flex;
  background-color: $color-white;

  a {
    @include bp(flex-direction, column, row, sm);

    display: flex;
    width: 100%;
    color: inherit;
    text-decoration: none;
  }

  @media screen and (orientation: portrait) {
    &:first-child {
      @include bp(margin-top, 0, 10px, sm);

      a {
        flex-direction: column;
      }

      .sale-point-image {
        @include bp(height, 197px, 255px, sm);

        width: 100%;
      }

      .panel {
        @include bp(padding, $double-gap, 22px 19px, sm);

        width: 100%;
      }
    }
  }

  @media screen and (orientation: landscape) {
    @include media-breakpoint-up(sm) {
      margin: 0 10px $double-gap 0;

      a {
        flex-direction: column;
        min-width: 445px;
      }
    }
  }

  .panel {
    @include bp(padding, $double-gap, 19px, sm);

    display: flex;
    flex: auto;
    justify-content: space-between;
    width: 100%;

    @media screen and (orientation: portrait) {
      @include bp(width, 100%, 50%, sm);
    }
  }

  .details {
    margin-right: $single-gap;
  }

  .sale-point-image {
    object-fit: cover;

    @media screen and (orientation: portrait) {
      @include bp(height, 197px, auto, sm);
      @include bp(width, 100%, 50%, sm);
    }

    @media screen and (orientation: landscape) {
      @include bp(height, 197px, 260px, sm);
    }
  }

  .arrow {
    @include bp(width, 24px, 33px, sm);
    @include bp(height, 24px, 33px, sm);

    display: flex;
    align-self: center;
  }

  .name {
    @include bp(font-size, $font-size-xl, 28px, sm);

    font-weight: $font-weight-medium;
    text-transform: uppercase;
  }

  .description {
    @include bp(font-size, $font-size, 18px, sm);
    @include bp(line-height, 18px, 24px, sm);
    @include bp(padding-top, 0, 19px, sm);

    @media screen and (orientation: landscape) {
      @include bp(padding-top, 0, 25px, sm);
    }

    font-weight: $font-weight-regular;
  }
}
