@import 'src/scss/base';

.footer {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: inherit;
  margin-bottom: 0;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 4px;
    background-image: linear-gradient(to bottom, rgba($color-midnight, 0), rgba($color-midnight, 0.2));
    transform: translateY(-100%);
    content: '';
  }

  .footer-controls {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: $double-gap;
    transform: translateY(-100%);
  }

  .checkout-button {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);
  }
}
