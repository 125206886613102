@import 'src/scss/base';
$app-icon-size: 40px;

.app-download-banner {
  position: sticky;
  top: 0;
  z-index: 2;
  display: flex;
  width: 100%;
  padding: $single-gap $double-gap;
  align-items: center;
  background-color: $color-dark-denim;

  @include bp(height, $banner-height-mobile, $banner-height-tablet, sm);
  @include bp(margin-bottom, calc($header-height-mobile * -1), calc($header-height-tablet * -1), sm);
}

.close-button {
  padding: 0;
  background: none;
  cursor: pointer;
  border: 0;
}

.app-icon {
  border-radius: 6px;
  width: $app-icon-size;
  height: $app-icon-size;
  margin: 0 $single-gap 0 $double-gap;
}

.info {
  display: flex;
  flex-direction: column;
  color: $color-white;
}

.title {
  @include bp(font-size, $font-size, $font-size-l, sm);
}

.description {
  @include bp(font-size, $font-size-xs, $font-size-m, sm);
}
