@import 'src/scss/base';

.rating-icons {
  display: inline-block;

  .icon {
    padding-top: 0;

    &.inactive {
      opacity: 0.4;
    }
  }
}
