@import 'src/scss/base';

.two-step-button {
  @include button-reset();

  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 $double-gap;
  color: $color-white;
  text-transform: capitalize;

  &:hover {
    color: $color-white;
    background-color: transparent;
  }

  &.clicked {
    flex-direction: row;
    padding: 11px $double-gap;
    box-shadow: -11px 1px 17px $color-white;
    clip-path: inset(0 -5px 0 -10px);

    &.caribbean-green {
      background-color: $color-caribbean-green;
    }

    &.new-york-pink {
      background-color: $color-new-york-pink;
    }

    svg {
      path {
        fill: $color-white;
      }
    }
  }

  .text {
    display: inline-block;
    max-width: 100px;
    margin-top: $half-gap;
    overflow: hidden;
    font-weight: $font-weight-medium;
    font-size: $font-size-xxs;
    font-family: $font-primary;
    white-space: nowrap;
    transition: all 300ms ease-in-out;

    &.buoy {
      color: $color-buoy;
    }

    &.new-york-pink {
      color: $color-new-york-pink;
    }

    &.clicked {
      margin-top: 0;
      margin-left: $single-gap;
      color: $color-white;
      font-size: $font-size;
    }
  }
}
