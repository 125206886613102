@import 'src/scss/base';

.tallink-silja-header {
  display: block;
  padding: 10px 0;
  text-align: center;
  background: $color-denim;

  @include media-breakpoint-up(xs) {
    padding: 12px 0;
    text-align: left;
  }

  .tallink-silj-logo-placeholder {
    max-width: $content-max-width;
    margin: 0 auto;
    line-height: 0;

    img {
      height: 20px;

      @include media-breakpoint-up(xs) {
        height: 26px;
      }
    }
  }
}
