@import 'src/scss/base';
$arrow-height: 1.55rem;

.panel-header {
  @extend %font-book;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $double-gap;
  text-transform: uppercase;

  &.with-edit-link {
    .title {
      line-height: 2.2rem;
    }
  }

  &.mobile {
    margin-bottom: $triple-gap;

    .title {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
  }

  &.with-underline {
    &.mobile {
      @extend %font-light;
      margin-top: $double-gap;
      margin-bottom: $single-gap;
      color: $color-white;
      border-bottom: 1px solid $color-white;
    }
  }

  .title {
    flex: 1 1 auto;
    margin-right: $single-gap;
    font-size: 2.4rem;
  }

  .edit-link {
    @extend %font-book;
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    padding: 0;
    color: $color-buoy;
    font-size: 1.2rem;
    line-height: inherit;
    white-space: pre-wrap;
    text-align: right;
    text-transform: inherit;
    text-decoration: none;
    background: transparent;
    border: 0;
    cursor: pointer;
  }

  .arrow-left {
    width: 1.1rem;
    height: $arrow-height;
    margin-right: 0.3rem;
  }

  .arrow-right {
    width: 0.95rem;
    height: $arrow-height;
    margin-left: 0.3rem;
  }
}
