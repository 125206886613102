@import 'src/scss/base';

.rating {
  display: flex;
  width: 100%;
  font-weight: $font-weight-medium;
  font-family: $font-primary;
  text-transform: capitalize;
}

.content {
  display: flex;
  align-items: flex-start;

  &.center {
    align-items: center;
  }

  &.column {
    flex-direction: column;
    width: 100%;

    .name {
      padding-bottom: $single-gap;
    }
  }

  &.row {
    .name {
      padding-right: $single-gap;
    }
  }

  &.inline {
    display: block;

    .name {
      display: inline;
    }
  }
}

.name {
  color: $color-dark-denim;

  &.white {
    color: $color-white;
  }

  &.default {
    font-size: $font-size;
  }

  &.medium {
    font-size: $font-size-m;
  }

  &.large {
    font-size: $font-size-l;
    line-height: rem(22);
  }
}
