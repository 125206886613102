@import '../../scss/base';

.terms-and-conditions {
  @include bp(margin, 0, auto 0, sm);
  @include bp(min-height, 100vh, auto, sm);

  display: flex;
  flex-direction: column;
  padding-top: env(safe-area-inset-top);
  background-color: $color-white;

  .title-container {
    display: flex;
    justify-content: space-between;
    padding: $double-gap;
    border-bottom: $border-side-geyser;
  }

  .close-button {
    align-self: baseline;
  }

  .title {
    padding-top: $single-gap;
    font-size: 23px;
    line-height: 1.25;
    text-transform: uppercase;
  }

  .accordion {
    font-size: $font-size-xl;
  }

  .accordion-title {
    padding-right: $double-gap;
  }

  .condition {
    font-weight: $font-weight-regular;
    font-size: $font-size;
    line-height: $font-size-l;
    white-space: break-spaces;
    text-align: left;
  }
}
