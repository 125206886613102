@import 'src/scss/base';

.button-icon {
  @include button-reset();

  &:disabled:hover {
    cursor: default;
  }

  @include focus-outline;

  &.white:focus-visible {
    outline: 1px dotted $color-white !important;
  }
}
