@import 'src/scss/base';

.confirmation-order {
  padding: 0 $double-gap calc(env(safe-area-inset-bottom) + #{$quintuple-gap});

  .sso-button,
  .field-text,
  .select-delivery-date-time,
  .select-stored-payment-method {
    @include bp(width, 100%, 70%, sm);
  }

  label {
    font-weight: $font-weight-regular;
  }

  .notice {
    display: flex;
    font-weight: $font-weight-regular;
    font-size: $font-size;
  }

  .notice-icon {
    margin-right: $single-gap;
  }

  .terms-and-conditions {
    margin-bottom: $double-gap;
    padding: $double-gap 0;
    font-weight: $font-weight-regular;
    font-size: $font-size;
    border-top: $border-side-geyser;
    border-bottom: $border-side-geyser;
  }

  .terms-and-conditions-button {
    color: $color-dark-denim;
    font-weight: $font-weight-medium;
    text-decoration: underline;
  }

  .create-order-button {
    margin: auto;

    @include bp(min-width, 100%, $breakpoint-up-xxs, sm);
  }

  .spinner {
    width: 20px;
    height: 20px;
  }
}

.with-toast {
  padding-bottom: calc(env(safe-area-inset-bottom) + #{$quintuple-gap} * 2.5);
}
