@import 'src/scss/base';

.total-price {
  $height: 30px;

  position: relative;
  z-index: $z-index-footer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: $height;
  padding: 0 $single-gap;
  background: $color-blue-midnight;

  &.stick-to-bottom {
    height: calc(env(safe-area-inset-bottom) + #{$height});
    padding: 0 $single-gap env(safe-area-inset-bottom);
  }

  &.light-theme {
    background: $color-white;

    .price,
    .label {
      color: $color-blue-midnight;
    }

    .spinner {
      path {
        fill: $color-blue-midnight;
      }
    }
  }

  .price,
  .label {
    color: $color-white;
    font-weight: $font-weight-medium;
  }

  .price {
    font-size: $font-size-l;
  }

  .label {
    font-size: $font-size;
  }

  .spinner {
    animation-duration: 1.5s;

    path {
      fill: $color-white;
    }
  }
}
