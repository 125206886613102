@import 'src/scss/base';

.page-header-description {
  @include bp(margin, $triple-gap, 55px 40px 47px, sm);

  display: flex;
  flex-direction: column;
  color: $color-white;
  text-align: center;

  @media screen and (orientation: landscape) {
    @include bp(margin, $triple-gap, 60px 20px 46px, sm);
  }

  .title {
    @include bp(font-size, 28px, 43px, sm);
    @include bp(margin-bottom, $single-gap, $double-gap, sm);
    @include bp(margin-top, 0, 13px, sm);

    font-weight: $font-weight-medium;
    text-transform: uppercase;
  }

  .description {
    @include bp(font-size, $font-size-m, $font-size-xl, sm);

    font-weight: $font-weight-regular;
  }
}
