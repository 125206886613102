@import 'src/scss/base';

.button {
  @include button-reset();
  color: $color-buoy;
  font-weight: $font-weight-medium;
  font-size: $font-size;
  font-family: $font-primary;

  @include focus-outline;

  &:hover,
  &:focus-visible {
    color: $color-caribbean-green;
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;

    &:hover {
      color: $color-buoy;
    }
  }
}
