@import 'src/scss/base';

.shadow {
  @media screen and (orientation: landscape) {
    @include media-breakpoint-up(sm) {
      position: relative;
      margin-top: $double-gap;
      margin-right: -22px;

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        width: $quadruple-gap;
        height: 100%;
        background-image: linear-gradient(to right, rgba($color-midnight, 0), rgba($color-midnight, 1));
        content: '';
      }
    }
  }

  .row {
    @include bp(padding-top, 0, $double-gap, sm);
    @include bp(
      padding-bottom,
      calc(env(safe-area-inset-bottom) + #{$bottom-navigation-height-mobile}),
      calc(env(safe-area-inset-bottom) + #{$bottom-navigation-height-tablet}),
      sm
    );

    @media screen and (orientation: landscape) {
      padding-bottom: calc(env(safe-area-inset-bottom) + #{$bottom-navigation-height-mobile});

      @include media-breakpoint-up(sm) {
        display: flex;
        flex-direction: row;
        padding-top: 0;
        overflow-x: scroll;
        overflow-y: hidden;
      }
    }
  }
}
