@import 'src/scss/base';

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $z-index-sidebar;
  overflow-y: hidden;
  background-color: rgba($color-black, 0.3);
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
  will-change: transform;

  &.opened {
    transform: translateX(0);
  }
}

.aside {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: calc(15px + env(safe-area-inset-top)) 15px calc(15px + env(safe-area-inset-bottom)) 15px;
  overflow: auto;
  color: $color-dark-denim;
  font-weight: $font-weight-regular;
  font-size: $font-size-m;
  background-color: $color-grey-smoke;

  @media screen and (orientation: landscape) {
    @include media-breakpoint-up(sm) {
      width: 30%;
    }
  }

  @include media-breakpoint-up(sm) {
    width: 50%;
  }

  .panels {
    flex-grow: 1;
  }

  .language {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .language-label {
    display: contents;
  }

  .select-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .select-language {
    padding-right: 50px;
    color: $color-dark-denim;
    font-weight: $font-weight-medium;
    font-size: $font-size;
    font-family: $font-primary;
    background-color: transparent;
    border: unset;
    outline: none;
    appearance: none;
  }

  .change-language {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events: none;

    .text {
      margin-top: $half-gap;
      color: $color-buoy;
      font-weight: $font-weight-medium;
      font-size: 9px;
    }
  }

  .footer {
    align-self: center;
  }
}
