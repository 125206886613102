@import 'src/scss/base';

.error {
  display: flex;
  align-items: center;
  padding-top: $single-gap;

  svg {
    width: 1.8rem;
    height: 1.65rem;
  }

  .message {
    margin-left: $single-gap;
    font-size: $font-size;
  }
}
