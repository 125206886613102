@import 'src/scss/base';

.back-button {
  @include button-reset();
  display: flex;
  align-items: center;

  @include focus-outline;

  &.white:focus-visible {
    outline: 1px dotted $color-white !important;
  }
}
