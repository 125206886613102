@use 'sass:math';
@import 'variables';

@mixin media-breakpoint-up($class) {
  @if $class == xxs {
    @media (min-width: $breakpoint-up-xxs) {
      @content;
    }
  } @else if $class == xs {
    @media (min-width: $breakpoint-up-xs) {
      @content;
    }
  } @else if $class == sm {
    @media (min-width: $breakpoint-up-sm) {
      @content;
    }
  } @else if $class == md {
    @media (min-width: $breakpoint-up-md) {
      @content;
    }
  } @else if $class == lg {
    @media (min-width: $breakpoint-up-lg) {
      @content;
    }
  } @else if $class == xl {
    @media (min-width: $breakpoint-up-xl) {
      @content;
    }
  } @else {
    @warn 'Breakpoint mixin supports: xxs, xs, sm, md, lg, xl';
  }
}

@mixin button-reset() {
  margin: 0;
  padding: 0;
  color: inherit;
  font-size: inherit;
  background: none;
  border: 0;
  border-radius: 0;
  outline: none;

  &:hover {
    cursor: pointer;
  }
}

@mixin link() {
  &:hover,
  &:focus-visible {
    color: $color-caribbean-green;
  }

  @include focus-outline();
}

@mixin bp($property, $default-value, $up-value, $breakpoint) {
  #{$property}: $default-value;

  @include media-breakpoint-up($breakpoint) {
    #{$property}: $up-value;
  }
}

@mixin button() {
  display: inline-block;
  width: 100%;
  min-height: 4rem;
  padding: 1.1rem 0.5rem 0.9rem;
  font-weight: normal;
  font-size: 1.6rem;
  font-family: $font-primary;
  line-height: 1.8rem;
  text-align: center;
  text-transform: uppercase;
  vertical-align: baseline;
  border: 0;
  cursor: pointer;
  @media not all and (hover: none) {
    &:hover {
      background-color: $color-grey-smoke;
    }
  }

  &:active {
    background-color: $color-grey-coin;
  }

  &:focus {
    outline-width: 0.1rem;
    outline-color: $color-dark-denim;

    .mobile-app & {
      outline: none;
    }
  }

  &[disabled] {
    color: $color-grey-coin;
    background-color: $color-light-grey;
    border: 0.1rem solid $color-grey-coin;
    outline-color: transparent;
    cursor: auto;

    &:hover {
      background-color: $color-light-grey;
    }
  }

  &.rounded {
    border-radius: 0.4rem;
  }
}

@mixin absolute-center($box-size) {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: math.div(-$box-size, 2);
  margin-left: math.div(-$box-size, 2);
}

@mixin focus-outline {
  &:focus {
    outline: none;

    &:focus-visible {
      outline: 1px dotted $color-dark-denim;
      outline-offset: 1px;
    }
  }
}
