@import 'src/scss/base';

.header {
  @include bp(height, $header-height-mobile, $header-height-tablet, sm);
  @include bp(font-size, $font-size-m, 23px, sm);
  @include bp(
    padding,
    env(safe-area-inset-top) $single-gap 0 $single-gap,
    env(safe-area-inset-top) $double-gap 0 $double-gap,
    sm
  );

  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  padding: env(safe-area-inset-top) $single-gap 0 $single-gap;
  color: $color-white;
  text-transform: uppercase;
  background: $color-dark-denim;

  .active {
    font-weight: $font-weight-bold;
  }

  .right-side {
    text-align: right;
  }
}
