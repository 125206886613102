@import 'src/scss/base';

.special-offers-list {
  @include bp(
    padding,
    $single-gap,
    19px 0 calc(env(safe-area-inset-bottom) + #{$gap-for-pay-button-special-offers}),
    sm
  );

  padding-bottom: calc(env(safe-area-inset-bottom) + #{$gap-for-pay-button-special-offers});

  @media screen and (orientation: landscape) {
    @include bp(
      padding,
      $single-gap,
      13px 0 calc(env(safe-area-inset-bottom) + #{$gap-for-pay-button-special-offers}),
      sm
    );
  }
}
