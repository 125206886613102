@import 'src/scss/base';

.confirmation-page {
  @include bp(margin, 0, auto 0, sm);
  @include bp(min-height, 100vh, auto, sm);

  display: flex;
  flex-direction: column;
  padding-top: env(safe-area-inset-top);
  font-weight: $font-weight-regular;
  font-size: $font-size;
  line-height: 18px;
  background-color: $color-white;

  .close {
    display: flex;
    justify-content: flex-end;
  }

  .close-button {
    @include button-reset();

    width: fit-content;
    padding: $double-gap;

    &:hover {
      background-color: unset;
    }
  }

  .confirmation-button {
    @include bp(margin-top, 0, $quintuple-gap, sm);

    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: center;
    padding: $double-gap;
    background-color: $color-porcelain;
  }
}
