@import 'src/scss/base';

.field-text-control-layout {
  position: relative;

  input {
    width: 100%;
  }

  .icons {
    position: absolute;
    top: 0;
    bottom: 0;
    left: auto;
    display: flex;
    align-items: center;
    pointer-events: none;

    &.xxs {
      right: 4px;
    }

    &.xs {
      right: 12px;
    }

    .button {
      @include button-reset();
      padding: 4px;
      outline: none;
      pointer-events: all;

      @include focus-outline;
    }
  }
}
