@import 'src/scss/base';

.select-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;

  .select {
    padding: 10px 16px;
    color: $color-dark-denim;
    font-weight: $font-weight-medium;
    font-size: $font-size-m;
    font-family: $font-primary;
    background: $color-white;
    border: solid 1px $color-light-slate-gray;
    outline: none;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
    appearance: none;
  }

  .icon {
    position: absolute;
    top: 50%;
    right: $double-gap;
    transform: translateY(-50%);
    pointer-events: none;
  }
}
