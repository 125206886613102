@import 'src/scss/base';

.toast {
  padding: $single-gap $double-gap calc(env(safe-area-inset-bottom) + #{$single-gap});
  color: $color-dark-denim;
  font-weight: $font-weight-medium;
  font-size: $font-size;
  text-align: center;

  &.primary {
    background-color: $color-onahau;
  }

  &.warning {
    background-color: $color-your-pink;
  }

  .close {
    margin-top: 14px;
    color: $color-dark-denim;
    text-decoration: underline;
  }
}
