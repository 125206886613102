@import 'src/scss/base';

.info-row {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 14px;

  &.as-column {
    @include bp(flex-direction, column, row, sm);

    margin-bottom: $single-gap;
    padding-bottom: $single-gap;
    border-bottom: $border-side-geyser;

    &:last-child {
      padding-bottom: 0;
      border-bottom: unset;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}
