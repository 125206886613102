@import 'src/scss/base';

.order-summary {
  padding: $double-gap;
  box-shadow: 0 4px 8px 0 rgba($color-black, 0.1);

  .title-container {
    display: flex;
    justify-content: space-between;
  }

  .close-button {
    display: flex;
  }

  .remove-button {
    align-self: flex-start;
    text-align: right;
  }

  .title {
    padding-top: $single-gap;
    font-weight: $font-weight-medium;
    font-size: $font-size-xxl;
    text-transform: uppercase;
  }

  .menu {
    display: flex;
    flex-direction: column;
    margin-top: $double-gap;
  }

  .menu-header {
    display: grid;
    grid-template-columns: 3fr 65px 1fr;
    margin-bottom: 14px;
    padding-bottom: $single-gap;
    border-bottom: $border-side-dark-denim;
  }

  .menu-title {
    font-weight: $font-weight-regular;
    font-size: $font-size-xs;
    text-align: right;

    &:first-child {
      text-align: left;
    }

    &:nth-last-child(2) {
      text-align: center;
    }
  }

  .product-name {
    line-height: 20px;
    text-align: left;

    &.unavailable-product-name {
      color: $color-light-slate-gray;
    }
  }

  .product-wrapper {
    margin-bottom: 14px;
    padding-bottom: 10px;
    font-size: $font-size;
    text-align: right;
    border-bottom: $border-side-geyser;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: unset;
    }
  }

  .product {
    display: grid;
    grid-template-columns: 3fr 65px 1fr;
    align-items: center;

    &.unavailable-product {
      grid-template-columns: 10fr 1fr;
    }
  }

  .warning {
    display: flex;
    align-items: center;
    margin-top: $single-gap;
    font-weight: $font-weight-regular;
    font-size: $font-size-xs;
  }

  .warning-icon {
    margin-right: $half-gap;
  }

  .price {
    text-align: right;
  }

  .delivery-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: $single-gap;
    padding-top: 10px;
    font-size: $font-size;
    border-top: $border-side-geyser;
  }

  .delivery {
    display: flex;
    justify-content: space-between;
  }

  .notice {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-weight: $font-weight-regular;
    font-size: $font-size-xs;
  }

  .notice-icon {
    margin-right: 6px;
  }

  .discount {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    padding-top: 12px;
    font-size: $font-size;
    line-height: 20px;
    border-top: $border-side-geyser;
  }

  .total-price {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    padding-top: $single-gap;
    font-size: $font-size-xl;
    border-top: 2px solid $color-dark-denim;
  }
}
