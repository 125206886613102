@import 'base';

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  color: $color-dark-denim;
  font-size: $font-size-m;
  font-family: $font-primary;
  background-color: $color-dark-denim;
}

main {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;

  > header {
    position: sticky;
    top: 0;
    z-index: 1;
    flex: 0 0 auto;
  }

  > section {
    position: relative;
    flex: 1 1 auto;
    background: $color-white;
  }
}
