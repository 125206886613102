@mixin font-face($font-name, $file-basename, $font-weight) {
  $font-url: '~@tallink/components-lib/src/fonts/' + $file-basename;

  @font-face {
    font-weight: $font-weight;
    font-family: $font-name;
    font-style: normal;
    src: url($font-url + '.woff') format('woff'), url($font-url + '.ttf') format('truetype');
    font-display: swap;
  }
}

@include font-face('GTWalsheim', 'GT-Walsheim-Pro-Regular', $font-weight-regular);
@include font-face('GTWalsheim', 'GT-Walsheim-Pro-Medium', $font-weight-medium);
@include font-face('GTWalsheim', 'GT-Walsheim-Pro-Bold', $font-weight-bold);
@include font-face('TallinkScriptBold', 'TallinkScriptBold', 700);

%text-base {
  font-family: GTWalsheim, Arial, sans-serif;
}

%font-light {
  @extend %text-base;
  font-weight: 200;
}
%font-book {
  @extend %text-base;
  font-weight: normal;
}
%font-medium {
  @extend %text-base;
  font-weight: 700;
}
