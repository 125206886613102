@import 'src/scss/base';

.shopping-page {
  flex-grow: 1;
  padding-top: env(safe-area-inset-top);
  background-color: $color-white;

  .vertical-line {
    width: 2px;
    height: $double-gap;
    margin-bottom: 5px;
    margin-left: 28px;
    background: linear-gradient(to bottom, $color-caribbean-green, $color-dark-denim);
  }
}
